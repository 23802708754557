import {modalNames} from 'constants/index';

import ErrorModal from './ErrorModal';
import ProgressModal from './ProgressModal';

const allModalsMap = {
    [modalNames.errorModal]: ErrorModal,
    [modalNames.progressModal]: ProgressModal,
};

export default allModalsMap;
