import React from 'react';
import {Input, Button, Form} from 'antd';
import {FieldInputProps, useField} from 'formik';

import styles from './styles.module.scss';

export interface MyInputProps extends FieldInputProps<number> {
    maxLength: number;
    onSubmit: () => void;
    placeholder: string;
}

const SearchInput: React.FC<MyInputProps> = ({maxLength, onSubmit, placeholder, ...props}): JSX.Element => {
    const [field, _, handlers] = useField(props);

    const isDisabled = field.value ? field.value.length < maxLength : true;

    const normalize = (value: any) => {
        const ttn = value?.trim().replace(/[^\d]/g, '');
        return ttn <= maxLength ? ttn : ttn?.substring(0, maxLength);
    };

    const onChange = ({target: {value}}: any) => handlers.setValue(normalize(value));

    const onSubmitHandler = (value: any) => {
        if (value.length === maxLength) {
            onSubmit();
        }
    };

    return (
        <div className={styles.search_input}>
            <Form.Item normalize={normalize} name={field.name}>
                <Input.Search
                    {...field}
                    size="large"
                    onSearch={onSubmitHandler}
                    placeholder={placeholder}
                    enterButton={
                        <Button type="primary" disabled={isDisabled}>
                            TRACK
                        </Button>
                    }
                    onChange={onChange}
                />
            </Form.Item>
        </div>
    );
};

export default SearchInput;
