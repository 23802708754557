import React from 'react';
import {Table, Empty} from 'antd';
import {useSelector} from 'react-redux';

import {getLoadSelector} from 'pages/Loads/selectors';

import {columnsSettings, getData} from './utils';

import styles from './styles.module.scss';

const ResultTable: React.FC = () => {
    const {
        load: {event_history, is_planned},
    } = useSelector(getLoadSelector);

    if (is_planned) {
        return null;
    }

    const emptyData = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    const showTotal = (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total} items`;

    return (
        <div className={styles.table}>
            <Table
                columns={columnsSettings()}
                dataSource={getData(event_history)}
                pagination={{
                    hideOnSinglePage: true,
                    showTotal,
                    pageSize: 20,
                    defaultCurrent: 1,
                    showQuickJumper: false,
                    showSizeChanger: false,
                }}
                locale={{emptyText: emptyData}}
            />
        </div>
    );
};

export default ResultTable;
