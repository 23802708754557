import {useDispatch} from 'react-redux';

import {login} from 'app/actions';

const useAppActions = () => {
    const dispatch = useDispatch();

    return {
        login: () => dispatch(login()),
    };
};

export default useAppActions;
