import React from 'react';
import {useSelector} from 'react-redux';
import {Steps} from 'antd';

import {getLoadSelector} from 'pages/Loads/selectors';

import styles from './styles.module.scss';

const HistoryStep: React.FC = () => {
    const {
        load: {ref, first_stop, last_stop},
    } = useSelector(getLoadSelector);

    if (!ref) {
        return null;
    }

    return (
        <div className={styles.history_step}>
            <div className={styles.history_step__title}>REF#{ref}</div>
            <Steps progressDot direction="vertical" className={styles.history_step__steps}>
                <Steps.Step description={first_stop} status="wait" />
                <Steps.Step description={last_stop} status="wait" />
            </Steps>
        </div>
    );
};

export default HistoryStep;
