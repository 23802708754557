import React from 'react';
import {useSelector} from 'react-redux';

// import {Icon} from 'antd';

import {LoadingOutlined} from '@ant-design/icons';

import {getShowLoadSpinner} from 'app/reducers/commonData/selectors';

import './style.css';

const LoaderSpinner = (): JSX.Element | null => {
    const showLoadSpinner = useSelector(getShowLoadSpinner);
    return showLoadSpinner ? (
        <div className="loading">
            <LoadingOutlined />
        </div>
    ) : null;
};

export default LoaderSpinner;
