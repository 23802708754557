type Handler = (() => void) | null;

interface Register {
    handler: Handler;
    getHandler(): Handler;
    setHandler(handler: () => void): void;
    removeHandler(): void;
}

const globalRegister: Register = {
    handler: null,
    getHandler() {
        return this.handler;
    },
    setHandler(func: any) {
        this.handler = func;
    },
    removeHandler() {
        this.handler = null;
    },
};

export default globalRegister;
