import {Load} from 'pages/Loads/types';

export const SET_LOAD_INFO = 'LOAD/SET_LOAD_INFO' as const;
export const CLEAR_LOAD_INFO = 'LOAD/CLEAR_LOAD_INFO' as const;
export const FETCH_LOAD_REQUEST = 'LOAD/FETCH_LOAD_REQUEST' as const;
export const FETCH_LOAD_FAILURE = 'LOAD/FETCH_LOAD_FAILURE' as const;

interface SetLoadInfo {
    type: typeof SET_LOAD_INFO;
    payload: {load: Load};
}

interface ClearLoadInfo {
    type: typeof CLEAR_LOAD_INFO;
}

interface FetchLoadRequest {
    type: typeof FETCH_LOAD_REQUEST;
}

interface FetchLoadFailure {
    type: typeof FETCH_LOAD_FAILURE;
}

export type LoadsActionTypes = SetLoadInfo | ClearLoadInfo | FetchLoadRequest | FetchLoadFailure;
