import {useDispatch} from 'react-redux';

import {getTTNInfo, clearTTNInfo} from 'pages/Loads/actions';
import {UseTTNActionType} from 'pages/Loads/types';
import {ThunkAction} from 'redux-thunk';
import {AppState} from 'app/reducers';
import {Action} from 'redux';

export default function (): UseTTNActionType {
    const dispatch = useDispatch();

    return {
        getTTNInfo: (ttn: number): ThunkAction<void, AppState, unknown, Action<string>> => dispatch(getTTNInfo(ttn)),
        clearTTNInfo: (): ThunkAction<void, AppState, unknown, Action<string>> => dispatch(clearTTNInfo()),
    };
}
