import React from 'react';
import {Layout} from 'antd';

import ModalProvider from 'components/modals/ModalProvider';
import Router from 'router';

import styles from './main.module.scss';

const Main = (): JSX.Element => {
    return (
        <Layout.Content role="main" className={styles.main}>
            <Router />
            <ModalProvider />
        </Layout.Content>
    );
};

export default Main;
