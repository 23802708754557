import React from 'react';
import {Field, Formik} from 'formik';
import {Form} from 'antd';

import SearchInput from 'components/SearchInput';
import {TTHFormProps} from 'pages/Loads/components/Form/types';

import {maxLength} from 'pages/Loads/components/Form/constants';

const TTNForm = (props: TTHFormProps) => {
    const {initialValues, onSubmit} = props;

    return (
        <div>
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                {({handleSubmit}) => (
                    <Form>
                        <Field
                            name="ttn"
                            type="number"
                            as={SearchInput}
                            onSubmit={handleSubmit}
                            placeholder="Truck Tracking Number"
                            maxLength={maxLength}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TTNForm;
