import React from 'react';

import {Modal, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import styles from './styles.module.scss';

const ProgressModal = (): JSX.Element => {
    const antIcon = <LoadingOutlined style={{fontSize: 130}} spin />;

    return (
        <Modal
            className="custom-modal"
            visible={true}
            closable={false}
            footer={null}
            style={{maxWidth: '100%'}}
            width=""
            wrapClassName="custom-modal-wrap"
        >
            <div className={styles.progress_content}>
                <Spin indicator={antIcon} />

                <div className={styles.progress_title}>searching load...</div>
            </div>
        </Modal>
    );
};

export default ProgressModal;
