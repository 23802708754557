import React from 'react';

import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import styles from './styles.module.scss';

const Spinner = (): JSX.Element => {
    const antIcon = <LoadingOutlined style={{fontSize: 130}} spin />;

    return (
        <div className={styles.progress_content}>
            <Spin indicator={antIcon} />
            <div className={styles.progress_title}>searching load...</div>
        </div>
    );
};

export default Spinner;
