import {Load} from 'pages/Loads/types';
import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';

import * as types from 'pages/Loads/actionTypes';
import * as Api from 'pages/Loads/requests';

import {AppState} from 'app/reducers';

export function setTTNInfo(load: Load): (dispatch: (params: types.LoadsActionTypes) => void) => void {
    return function (dispatch) {
        dispatch({
            type: types.SET_LOAD_INFO,
            payload: {
                load,
            },
        });
    };
}

export function clearTTNInfo(): (dispatch: (params: types.LoadsActionTypes) => void) => void {
    return function (dispatch) {
        dispatch({
            type: types.CLEAR_LOAD_INFO,
        });
    };
}

export function requestTTNInfo(): (dispatch: (params: types.LoadsActionTypes) => void) => void {
    return function (dispatch) {
        dispatch({
            type: types.FETCH_LOAD_REQUEST,
        });
    };
}

export function failureTTNInfo(): (dispatch: (params: types.LoadsActionTypes) => void) => void {
    return function (dispatch) {
        dispatch({
            type: types.FETCH_LOAD_FAILURE,
        });
    };
}

export function getTTNInfo(ttn: number): ThunkAction<void, AppState, unknown, Action<string>> {
    return async function (dispatch) {
        dispatch(requestTTNInfo());

        try {
            const ttnInfo = await Api.getLoadInfo(ttn);

            dispatch(
                setTTNInfo({
                    ttn,
                    ...ttnInfo,
                }),
            );
        } catch (error) {
            dispatch(failureTTNInfo());
        }
    };
}
