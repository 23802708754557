import React from 'react';
import {Route, Switch} from 'react-router-dom';

import LoadsPage from 'pages/Loads';
import NotFound from 'pages/NotFound';

export default () => (
    <Switch>
        <Route exact path="/" component={LoadsPage} />

        <Route component={NotFound} />
    </Switch>
);
