import isFunction from 'lodash/isFunction';

import {authApi} from 'services/api';

import * as types from 'app/actions/actionTypes';

import globalRegister from 'utils/globalRegister';

export function showLoaderSpinner() {
    return {
        type: types.SHOW_LOADER_SPINNER,
    };
}

export function hideLoaderSpinner() {
    return {
        type: types.HIDE_LOADER_SPINNER,
    };
}

export function showModal(params: any) {
    const {name, mode, onSubmit, closeBeforeSubmit, data} = params;

    if (onSubmit && isFunction(onSubmit)) {
        globalRegister.setHandler(onSubmit);
    }

    return {
        type: types.SHOW_MODAL,
        payload: {
            name,
            mode,
            data,
            closeBeforeSubmit,
        },
    };
}

export function hideModal() {
    globalRegister.removeHandler();
    return {
        type: types.HIDE_MODAL,
    };
}

export const setAccessToken = (token) => ({type: types.ACCESS_TOKEN_RECEIVED, payload: token});

export const login = () => async (dispatch) => {
    try {
        const {data} = await authApi.post('/token', {username: 'any-any', password: '2u8F2h2FZ.gnZ'});

        if (data?.access_token) {
            dispatch(setAccessToken(data.access_token));
        }
    } catch (error) {
        console.error(error);
    }
};
