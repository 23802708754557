import {Load} from 'pages/Loads/types';
import * as types from 'pages/Loads/actionTypes';

export interface TTNState {
    load: Load;
    isLoading: boolean;
    isError: boolean;
    isHide: boolean;
}

export const defaultLoadsState: TTNState = {
    load: {
        ttn: undefined,
        broker: undefined,
        carrier: undefined,
        first_stop: undefined,
        last_stop: undefined,
        ref: undefined,
        event_history: undefined,
        is_planned: undefined,
    },
    isLoading: false,
    isError: false,
    isHide: true,
};

export default function giftsReducer(state: TTNState = defaultLoadsState, action: types.LoadsActionTypes): TTNState {
    switch (action.type) {
        case types.SET_LOAD_INFO: {
            const {load} = action.payload;
            return {
                ...state,
                load,
                isLoading: false,
                isError: false,
            };
        }

        case types.CLEAR_LOAD_INFO: {
            return {...state, isHide: true};
        }

        case types.FETCH_LOAD_REQUEST: {
            return {...state, isLoading: true, isError: false, isHide: false};
        }

        case types.FETCH_LOAD_FAILURE: {
            return {...state, isError: true, isLoading: false, isHide: false};
        }

        default:
            return state;
    }
}
