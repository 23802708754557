import React from 'react';
import {Tag} from 'antd';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';

import getDateForCurrentTimeZone from 'pages/Loads/utils/getDateForCurrentTimeZone';

export const getColor = (status: string): string =>
    status === 'completed' || status === 'DEL on site' || status === 'PU on site' ? 'green' : 'blue';

export const columnsSettings = () => [
    {
        key: 'location',
        title: 'Last Location',
        dataIndex: 'location',
    },
    {
        key: 'datetime',
        title: (
            <>
                <span className="sort_prefix">Sort by</span> Date and Time
            </>
        ),
        dataIndex: 'datetime',
        sorter: (
            a: {location: string; datetime: string; status: string},
            b: {location: string; datetime: string; status: string},
        ) => moment(a.datetime).unix() - moment(b.datetime).unix(),
        render: (datetime: string) => getDateForCurrentTimeZone(datetime),
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => <Tag color={getColor(status)}>{status}</Tag>,
    },
];

export const getData = (arr: {datetime: string; location: string; status: string}[] | undefined) =>
    arr?.length ? arr?.map((item) => ({...item, key: uuidv4()})) : [];
