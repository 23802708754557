import React from 'react';
import {connect} from 'react-redux';
import isFunction from 'lodash/isFunction';

import * as appActions from 'app/actions';

import globalRegister from 'utils/globalRegister';

import modalsMap from '../modalsMap';

const ModalContainer = ({modalData, onClose}: any): JSX.Element | null => {
    if (!modalData) {
        return null;
    }
    const {name, data, closeBeforeSubmit = true} = modalData;
    const onSubmitHandler = globalRegister.getHandler();
    const onSubmit = (fields: any) => {
        if (closeBeforeSubmit) {
            onClose();
        }
        if (isFunction(onSubmitHandler)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onSubmitHandler(fields);
        }
    };

    const CurrentModal = modalsMap[name] || null;

    return <CurrentModal data={data} onSubmit={onSubmit} onClose={onClose} />;
};

export default connect(
    state => {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            modalData: state.app.modalData,
        };
    },
    dispatch => {
        return {
            onClose: () => dispatch(appActions.hideModal()),
        };
    },
)(ModalContainer);
