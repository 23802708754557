import React from 'react';
import {useSelector} from 'react-redux';
import {Button} from 'antd';

import {getLoadSelector} from 'pages/Loads/selectors';
import useTTNActions from 'pages/Loads/hooks/useTTNActions';

import PlannedLoad from '../PlannedLoad';
import HistoryStep from '../HistoryStep ';
import ResultTable from '../Table';

import styles from './styles.module.scss';

const LoadsResult: React.FC = () => {
    const {load} = useSelector(getLoadSelector);
    const {ttn, is_planned} = load;
    const {getTTNInfo, clearTTNInfo} = useTTNActions();

    const refreshTTNInfo = () => {
        getTTNInfo(ttn as number);
    };

    const closeModal = () => {
        clearTTNInfo();
    };

    return (
        <>
            <PlannedLoad />

            <HistoryStep />

            {!is_planned && (
                <div className={styles.refresh_button}>
                    <Button onClick={refreshTTNInfo} size="middle">
                        Refresh
                    </Button>
                </div>
            )}

            <ResultTable />

            <div className={styles.close_button_container}>
                <Button onClick={closeModal} type="primary" size="middle">
                    OK
                </Button>
            </div>
        </>
    );
};

export default LoadsResult;
