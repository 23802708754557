import React from 'react';
import {useSelector} from 'react-redux';

import {getLoadSelector} from 'pages/Loads/selectors';

import styles from './styles.module.scss';

const PlannedLoad: React.FC = () => {
    const {
        load: {is_planned},
    } = useSelector(getLoadSelector);

    if (!is_planned) {
        return null;
    }

    return (
        <div className={styles.planned_load}>
            <div className={styles.planned_load__title}>Planned load</div>
            <div className={styles.planned_load__text}>
                The truck has been dispatched on your load and will go to the pickup as soon as it gets unloaded on its
                current trip.
            </div>
        </div>
    );
};

export default PlannedLoad;
