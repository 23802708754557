import React from 'react';

import {Button} from 'antd';

import {CloseCircleFilled} from '@ant-design/icons';

import TitleIcon from 'components/TitleIcon';

import styles from './styles.module.scss';

const ErrorMessage = ({onClose}: any): JSX.Element => (
    <div className={styles.error_content}>
        <TitleIcon icon={<CloseCircleFilled className={styles.error_icon} />} titleText="load not found" />

        <div className={styles.error_context}>
            <div>Check typed tracking number and try again.</div>

            <div className={styles.footer_error}>
                <Button type="primary" danger size="large" onClick={onClose}>
                    OK
                </Button>
            </div>
        </div>
    </div>
);

export default ErrorMessage;
