import React from 'react';
import {TitleIconPropsType} from 'components/TitleIcon/types';
import styles from './styles.module.scss';

const TitleIcon = (props: TitleIconPropsType): JSX.Element => {
    const {icon, titleText} = props;
    return (
        <div className={styles.title_icon}>
            {icon}
            <div className={styles.title}>{titleText}</div>
        </div>
    );
};

export default TitleIcon;
