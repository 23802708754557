import React, {useEffect} from 'react';
import {Layout} from 'antd/lib';

import LoaderSpinner from 'components/LoaderSpinner';

import useAppActions from 'app/hooks/useAppActions';

import Main from '../layout/main/index';
import './css/custom.scss';

const App: React.FC = () => {
    const {login} = useAppActions();

    useEffect(() => {
        login();
    }, []);

    return (
        <div>
            <Layout>
                <Main />
            </Layout>
            <LoaderSpinner />
        </div>
    );
};

export default App;
