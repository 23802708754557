import {combineReducers} from 'redux';

import commonData, {CommonDataState} from 'app/reducers/commonData';
import loadState, {TTNState} from 'pages/Loads/reducers';

const rootReducer = combineReducers({app: commonData, loadState});

export interface AppState {
    commonData: CommonDataState;
    loadState: TTNState;
}

export default rootReducer;
