import React from 'react';
import {useSelector} from 'react-redux';
import {CSSTransition} from 'react-transition-group';

import ErrorMessage from 'pages/Loads/components/ErrorMessage';
import Spinner from 'pages/Loads/components/Spinner';

import {getLoadSelector} from 'pages/Loads/selectors';
import useTTNActions from 'pages/Loads/hooks/useTTNActions';

import LoadsResult from 'pages/Loads/components/LoadsResult';

import styles from './styles.module.scss';

const LoadsResultContainer: React.FC = () => {
    const {isError, isLoading, isHide} = useSelector(getLoadSelector);
    const {clearTTNInfo} = useTTNActions();

    const spinner = isLoading && <Spinner />;
    const error = isError && <ErrorMessage onClose={clearTTNInfo} />;
    const result = !isLoading && !isError && <LoadsResult />;

    const timeout = {enter: 600, exit: 300};
    const classNames = {
        enter: styles.load_enter,
        enterActive: styles.load_enter_active,
        exit: styles.load_exit,
        exitActive: styles.load_exit_active,
        exitDone: styles.load_exit_done,
    };

    return (
        <CSSTransition in={!isHide} timeout={timeout} classNames={classNames} unmountOnExit mountOnEnter>
            <div className={styles.result_block}>
                {spinner}
                {error}
                {result}
            </div>
        </CSSTransition>
    );
};

export default LoadsResultContainer;
