import React from 'react';

import useTTNActions from 'pages/Loads/hooks/useTTNActions';

import Form from 'pages/Loads/components/Form/TTNForm';
import LoadsResultContainer from 'pages/Loads/containers/LoadsResultContainer';

import styles from './loads.module.scss';

const Loads = (): JSX.Element => {
    const {getTTNInfo} = useTTNActions();

    const handleSubmit = ({ttn}: {ttn: number | undefined}) => {
        if (ttn) {
            getTTNInfo(ttn);
        }
    };

    const initialValues: {ttn: number | undefined} = {ttn: undefined};
    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.title}>Track a load</div>

                <div className={styles.container__content}>
                    <div>Track your load movement by entering TTN below to get information.</div>
                    <div>Check your email to find number or contact carrier.</div>
                </div>

                <div className={styles.inputMargin}>
                    <Form onSubmit={handleSubmit} initialValues={initialValues} />
                </div>
                <div className={styles.bgImage} />
            </div>

            <LoadsResultContainer />
        </div>
    );
};

export default Loads;
