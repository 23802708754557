import * as types from 'app/actions/actionTypes';

export interface CommonDataState {
    modalData: {modalName: string; modalData: any} | undefined;
    auth: {isLoggedIn: boolean};
    showLoaderSpinner: boolean;
    accessToken: string | null;
}

export const defaultCommonState: CommonDataState = {
    auth: {isLoggedIn: false},
    showLoaderSpinner: false,
    modalData: undefined,
    accessToken: null,
};

export default (state = defaultCommonState, action): CommonDataState => {
    switch (action.type) {
        case types.ACCESS_TOKEN_RECEIVED: {
            return {
                ...state,
                accessToken: action.payload,
            };
        }

        case types.SHOW_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: true,
            };
        }

        case types.HIDE_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: false,
            };
        }

        case types.SHOW_MODAL: {
            return {
                ...state,
                modalData: action.payload,
            };
        }

        case types.HIDE_MODAL: {
            return {
                ...state,
                modalData: undefined,
            };
        }

        default:
            return state;
    }
};
