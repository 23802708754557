import axios from 'axios';

import {getAccessToken} from 'app/reducers/commonData/selectors';
import store from 'app/store';

const publicURL = '/public_api';
const authURL = '/auth';

const publicApi = axios.create({baseURL: publicURL});
const authApi = axios.create({baseURL: authURL});

publicApi.interceptors.request.use(
    (config) => {
        const state = store.getState();

        const accessToken = getAccessToken(state);

        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
    },
    (error) => Promise.reject(error),
);

export {publicApi, authApi};
